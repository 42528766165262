// ant design
@import '~antd/dist/antd.css';

// custom variables
@import 'variables';

html,
body {
	height: 100%;
	font-family: $main-family;

	#root {
		height: 100%;
	}
}

// custom menu
ul.custom-menu {
	padding: 5px;

	> li {
		margin: 5px 0;
	}
}
