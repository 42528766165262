@import '../../../../styles/variables';

.ant-layout {
	min-height: 100%;
}

.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

aside.ant-layout-sider > .ant-layout-sider-children {
	position: fixed;
	width: inherit;
}

.logo {
	height: 40px;
	margin: 8px;
	background: url(logo.png) center/cover no-repeat;
}

.site-layout .site-layout-background {
	background: #fff;
}

.user-avatar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 24px 0;
}
.user-detail-headings {
	color: #808080;
	margin-left: 10px;
}
.image-preview {
	width: 390px;
	height: 270px;

	border: 1px solid #808080;
	border-radius: 12px;
}
.adminpox-heading {
	font-weight: 400;
	font-size: 23px;
	line-height: 28px;
}
.site-page-header {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 23px;
	line-height: 28px;
	display: flex;
	align-items: center;

	color: #000000;
}
.pox-detail {
	margin: '10px';
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	font-size: 19px;
	line-height: 23px;
	display: flex;
	align-items: center;
	opacity: 0.8;
	color: #000000;
}
.pox-detail-value {
	margin: 5px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	font-size: 19px;
	line-height: 23px;
	display: flex;
	align-items: center;

	color: #000000;
}
.settings-heading {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 49px;
	line-height: 55px;
	color: #4274c4;
	text-align: center;
}
.settings-page-outer{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 88vh;
}
.settings-page-inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70%;
	height: 75%;
}
.settings-image{
	width: 80%;
	height: 80%;
}
