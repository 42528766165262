.search-button {
	border-radius: 32px !important;
	height: 30px !important;
	font-size: 13px !important;
}
.search-input{
    margin-right:10px !important;width:30% !important;
}
@media (max-width:1024px) {
    .search-button{
        width:20px !important;
	font-size: 10px !important;

    }
    .search-input{
        width:50% !important;
    }
}
@media (max-width:810px){
    .search-input{
        width:40% !important;
    }
}
@media (max-width:670px){
    .search-input{
        width:60% !important;
        margin-bottom:5px !important;
    }
    .search-bar{
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .search-button{
        width:15px !important;
	font-size: 9px !important;

    }
    .list{
        width:70% !important;
    }
    // .list-text{
    //     font-size: 8px !important;
    // }
}
