.section-auth {
	display: flex;
	background: linear-gradient(#1c2167, #2b468d, #3964b3);
	height: 100vh;
	overflow-y: hidden;
	position: relative;
	flex-wrap: nowrap;
	flex-grow: initial;
	min-height: 800px;
}

.section-auth::after {
	position: absolute;
	content: '';
	height: 150vh;
	min-height: 1200px !important;
	top: -25vh;
	right: 0;
	width: 70vw;
	background-color: #ddd;
	border-radius: 80% 0% 0 80%;
}

.section-logo {
	position: absolute;
	top: 20px;
	left: 5%;
}

.section-logo img {
	height: 48px;
	margin-left: -3%;
}

.section-logo p {
	color: white;
}

.section-forms {
	color: #2b468d;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

/* this code for glob image  */
.view-img {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.view-img img {
	width: 35vw;
	max-width: 600px;
}

/* this is form control section  */
.form-control {
	background-color: transparent;
	border: none;
	flex: 1;
	max-width: 600px;
	height: 80%;
	margin-right: 15%;
}

.header-text {
	text-align: center;
	color: #2b468d;
	font-weight: 500;
	font-size: 1.5rem;
	margin-bottom: 98px;
}

.text {
	color: #2b468d;
	font-size: 18px;
}

.login-btn {
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
}

.login-btn button {
	width: 100%;
	color: #042954;
	font-size: 1.35em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.google-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 520px;
	box-shadow: 2px 2px 10px #8493b8 !important;
	border-radius: 5px !important;
	color: #1c2167 !important;
	border: 1px solid #6469b3 !important;
}

.divider {
	display: flex;
	align-items: center;
	margin: 25px auto;
	width: 540px;
}

.divider span {
	margin: 0 0.5rem;
}

.divider hr {
	flex: 1;
	background: #042954 !important;
	opacity: 0.2;
}

.form-section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.flex-group {
	width: 520px;
	display: flex;
	align-items: center;
}

.flex-group .input-group {
	display: flex;
	align-items: center;
	background-color: white;
	border-radius: 5px;
	border: 1px solid #4374c3;
	margin: 10px 0px;
	margin-right: 30px;
}

.flex-group input {
	border-radius: 5px;
	border: 1px solid #ddd;
	height: 50px;
	text-indent: 10px;
}

.flex-group button {
	background-color: #4374c3;
	border-radius: 3px;
	border: 1px solid #4374c3;
	width: 150px;
	height: 36px;
	font-size: 15px;
	cursor: pointer;
	color: white;
}

button.btn-outline {
	border: none;
	background-color: transparent;
	color: #4374c3;
}

button.btn-outline:hover {
	border-bottom: 1px solid #4374c3 !important;
}

.flex-group .input-form input {
	border: none !important;
	border-radius: 5px !important;
}

.input-group input {
	border: none;
	border-left: 1px solid gray;
	border-radius: 0px;
	width: auto !important;
}

.input-group span {
	padding: 0 0.5rem;
}

.submit-section {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
}

.submit-section .btn-submit {
	background-color: #4374c3;
	color: white;
	border-radius: 50px;
	border: none;
	width: 520px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	font-size: 1rem;
}
